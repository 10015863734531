export const menus= [
    {
      text: "Compagnie",
      icon: "mdi-view-dashboard",
      to: "/dashcommerce",
      menu: "0",
      model: true,
      children: [
        // {
        //   text: "About",
        //   icon: "mdi-view-dashboard",
        //   to: "/about",
        // },
        {
          text: "Dashboard",
          icon: "mdi-view-dashboard",
          to: "/dashboard",
        },
        {
          text: "Work Board",
          icon: "mdi-human-male-board",
          code: "001",
          to: "/wells",
          title:"Wells list",
          model: false,
        },
        {
          text: "Production Data",
          icon: "mdi-database",
          code: "009",
          to: "/productions",
          title:"Production Data",
          model: false,
        },
           {
          text: "Planning",
          icon: "mdi-calendar-month",
          code: "002",
           to: "/planning",
          title:"Wells Planning Detail",
          model: false,
         children:[
         {
          text: "Integrity Planning",
          model: false,
          code: "003",
           to: "/planning",
        },
         {
          text: "Rigless planning",
          model: false,
          code: "003",
        },
		]
        },

        {
          text: "Tests",
          icon: "mdi-clipboard-check-outline",
          model: false,
          to: "/tests",
          title:"Global Tests List",
          code: "003"
        },
       {
          text: "Data Maping",
          icon: "mdi-chart-tree",

          model: false,
         children:[
         {
          text: "Corrosions",
          model: false,
          code: "003",
          to: "/corrosions",
        },
        {
          text: "Cements",
          model: false,
          code: "003",
          to: "/cements",
        },
        {
          text: "Formation Correlation",
          icon: "mdi-wrench-cog-outline",
          to: "/formationanalyse",
          code: "004",
          model: false,
        },
           ]
        },
        // {
        //   text: "Failures",
        //   icon: "mdi-check-decagram",
        //   code: "005",
        //   model: false,
        //   to: "/dictionnaire3",
        //   hiden: false,
        // },
        {
          text: "Repair ",
          icon: "mdi-tools",
          code: "006",
          to: "/reparations",
          model: false,
        },
        // {
        //   text: "Actions ",
        //   icon: "mdi-cash",
        //   code: "007",
        //   to: "/dictionnaire5",
        //   model: false,
        // },

        // {
        //   text: "Maps",
        //   icon: "mdi-wrench-cog-outline",
        //   to: "/map",
        //   code: "008",
        //   model: false,
        // },
        {
          text: "Administration ",
          icon: "mdi-office-building-cog",
          children: [
            {
              text: "Users",
              icon: "mdi-file-table-box-multiple",
              to: "/users",
              code: "0A",
            },
            {
              text: "Tables",
              title: "Données de Base",
              icon: "mdi-file-table-box-multiple",
              to: "/dictionnary",
              code: "0A",
            },
        ]
		},

      ],
    },
  ]